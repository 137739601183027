import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import {  RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'app-login', loadChildren: './app-login/app-login.module#AppLoginModule' }, // 钉钉登录
  { path: 'tabs', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'home', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'tabs/home', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'hidden-reporting', loadChildren: './hidden-danger-reporting/hidden-danger-reporting.module#HiddenDangerReportingModule'},
  { path: 'immediate-inspection', loadChildren: './immediate-inspection/immediate-inspection.module#ImmediateInspectionModule'},
  { path: 'incident-corrective-execution', loadChildren: './incident-corrective-execution/incident-corrective-execution.module#IncidentCorrectiveExecutionModule' },
  { path: 'incident-corrective-verification', loadChildren: './incident-corrective-verification/incident-corrective-verification.module#IncidentCorrectiveVerificationModule' },
  { path: 'announcement', loadChildren: './announcement/announcement.module#AnnouncementPageModule' },
  { path: 'announcement-detail', loadChildren: './announcement-detail/announcement-detail.module#AnnouncementDetailPageModule' },
  { path: 'message-center', loadChildren: './message-center/message-center.module#MessageCenterPageModule' },
  { path: 'message-detail', loadChildren: './message-detail/message-detail.module#MessageDetailPageModule' },
  { path: 'setting-host', loadChildren: './setting-host/setting-host.module#SettingHostPageModule' },
  { path: 'task-center', loadChildren: './task-center/task-center.module#TaskCenterPageModule' },
  { path: 'message-detail', loadChildren: './message-detail/message-detail.module#MessageDetailPageModule' },
  { path: 'record-hidden-danger', loadChildren: './record-hidden-danger/record-hidden-danger.module#RecordHiddenDangerPageModule' },
  { path: 'inspection-forms', loadChildren: './immediate-inspection/inspection-form/inspection-form.module#InspectionFormModule' },
  { path: 'tab-calendar',loadChildren:'./tab-calendar/tab-calendar.module#TabCalendarPageModule' },
  { path: 'add-finding-page', loadChildren: './immediate-inspection/inspection-form/add-finding-page/add-finding-page.module#AddFindingPageModule' },
  { path: 'add-action-page', loadChildren: './immediate-inspection/inspection-form/add-action-page/add-action-page.module#AddActionPageModule' },
  { path: 'record-plan-inspection', loadChildren: './record-plan-inspection/record-plan-inspection.module#RecordPlanInspectionPageModule' },
  { path: 'record-corrective', loadChildren: './record-corrective/record-corrective.module#RecordCorrectivePageModule' },
  { path: 'record-inspection', loadChildren: './record-inspection/record-inspection.module#RecordInspectionPageModule' },
  { path: 'pic-single', loadChildren: './pic-single/pic-single.module#PictureSingleComponentModule' },
  { path: 'turn-to-send', loadChildren: './shared/components/turn-to-send/turn-to-send.module#TurnToSendPageModule' },
  // 合规文件上传
  { path: 'upload-compliance-documents', loadChildren: './upload-compliance-documents/upload-compliance-documents.module#UploadComplianceDocumentsModule' },
  { path: 'preview-file', loadChildren: './shared/components/preview-file/preview-file.module#PreviewFileModule' },
  // 政府检查上报
  { path: 'gov-reporting', loadChildren: './gov-reporting/gov-reporting.module#GovReportingModule'},
  { path: 'gov-adding', loadChildren: './gov-reporting/gov-add/gov-add.module#GovAddModule'},
  { path: 'record-government', loadChildren: './record-government/record-government.module#RecordGovernmentModule'},
  { path: 'e-learning', loadChildren: './e-learning/e-learning.module#ELearningPageModule' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    // RouterModule.forRoot(routes, { useHash: false,preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
