import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../../../../service';
import { Router } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi

@Component({
	selector: 'person-select-modal',
	templateUrl: './person-select-modal.component.html',
	styleUrls: ['./person-select-modal.component.scss'],
})
export class PersonSelectModalComponent implements OnInit {
  queryCriterias = [];
  selectedPerson;
  optionList = [];
  searchMessage;
  modalTitle;
  source;
  peopleInOrgId;

	constructor(
		public dataService: DataService,
		public appService: AppService,
		public router: Router,
		public navCtrl: NavController,
		public navParams: NavParams,
		public modalController: ModalController,
    public localStorageService: LocalStorageService
  ) {
		let params = this.navParams.data;
    this.modalTitle = params.modalTitle;
    this.selectedPerson = params.selectedPerson;
    this.peopleInOrgId = params.peopleInOrgId;
    this.source = params.source;
    // this.dataService.queryOrg(null, this.parentId, this.orgType[this.type]).subscribe(res => {
    //   if (res && res['STATUS'] === 0) {
    //     this.optionList = res['DATA'];
    //   }
    // });
    this.getPerson();
	}

	ngOnInit() {
		// 设置标题
		document.getElementsByTagName('title').item(0).innerText = '选择' + this.modalTitle;
	}
  
  getPerson() {
    if (this.source === 'auto') {
      if (this.peopleInOrgId) {
        this.dataService.getEmployeeByOrgId(this.searchMessage, this.peopleInOrgId).subscribe(res => {
          if (res && res['STATUS'] === 0) {
            this.optionList = res['DATA'];
          }
        });
      } else {
        this.dataService.getEmployeeByOrgId(this.searchMessage, null).subscribe(res => {
          if (res && res['STATUS'] === 0) {
            this.optionList = res['DATA'];
          }
        });
      }
    }

  }

  markSelected(p) {
    if (this.selectedPerson === p) {
      this.selectedPerson = null;
    } else {
      this.selectedPerson = p;
    }
  }

  isSelected(p) {
    if (this.selectedPerson && p.id === this.selectedPerson.id) {
      return true;
    }
    return false;
  }

	cancel() {
		this.modalController.dismiss();
	}

	confirm() {
		this.modalController.dismiss({ person: this.selectedPerson });
	}
}
