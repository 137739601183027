/*
 * @Author: Xu Xingshan 
 * @Date: 2019-03-20 23:10:41 
 * @Last Modified by: Xu Xingshan
 * @Last Modified time: 2019-09-26 23:30:17
 */
 import { Injectable } from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
 import { LocalStorageService } from '../service/localstorage.service';
 import { Observable, Subject } from 'rxjs/Rx';
 import { of } from 'rxjs';
 import { Router } from '@angular/router';
 import { resolve } from 'dns';
 import { rejects } from 'assert';
 import { QueryCriteria } from '../shared/components/filter-modals/query-criteria.model';

 const TIME_OUT = 60000;
 @Injectable()
 export class DataService {
  public pageSize = 10;
  public initBasic = true;
  public pendding = 0;
  public todo = 0;
  public urlPrefix = this.localStorageService.getStore('API_DOMAIN') + this.localStorageService.getStore('API_DIR');
  public refresh = false;

  public returnSwipe = new Subject<any>();
  returnSwipeObservable = this.returnSwipe.asObservable();

  constructor(
    private http: HttpClient,
    public router: Router,
    public localStorageService: LocalStorageService) {
  }
  updatePrefix() {
    this.urlPrefix = this.localStorageService.getStore('API_DOMAIN') + this.localStorageService.getStore('API_DIR');
  }
  // 封装POST
  post(url, para, isAuth) {
    let headers = new HttpHeaders();
    const isMedical = url.indexOf('medicalSatisfactionInspection') !== -1 ? true : false;
    if (isAuth && !isMedical) {
      headers = new HttpHeaders().set('token', this.localStorageService.getStore('token'));
    }
    let fullUrl = this.urlPrefix + url;
    return this.http.post(fullUrl, para, { headers: headers })
    .timeout(TIME_OUT)
    .catch((err: Response) => {
      if (err.status == 401 || err.status === undefined) {
        this.localStorageService.removeStore('token')
          //  this.appService.toastTip('get_data_failed_key',true);
          //  this.router.navigateByUrl('/login');
          this.router.navigate(['/login']);

        }
        //  let errDetail = err.eror;
        //  console.log(errDetail);
        return Observable.throw(new Error(err.toString()));
      })
  }
  // 封装PUT
  put(url, para, isAuth) {
    let headers = new HttpHeaders();
    if (isAuth) {
      headers = new HttpHeaders().set('token', this.localStorageService.getStore('token'));
    }
    let fullUrl = this.urlPrefix + url;
    return this.http.put(fullUrl, para, { headers: headers })
    .timeout(TIME_OUT)
    .catch((err: Response) => {
      if (err.status == 401 || err.status === undefined) {
        this.localStorageService.removeStore('token')
          //  this.appService.toastTip('get_data_failed_key',true);
          //  this.router.navigateByUrl('/login');
          this.router.navigate(['/login']);

        }
        //  let errDetail = err.eror;
        //  console.log(errDetail);
        return Observable.throw(new Error(err.toString()));
      })
  }
  // 封装DELETE
  delete(url, isAuth) {
    let headers = new HttpHeaders();
    if (isAuth) {
      headers = new HttpHeaders().set('token', this.localStorageService.getStore('token'));
    }
    let fullUrl = this.urlPrefix + url;
    return this.http.delete(fullUrl, { headers: headers })
    .timeout(TIME_OUT)
    .catch((err: Response) => {
      if (err.status === 401 || err.status === undefined) {
        this.localStorageService.removeStore('token')
          //  this.appService.toastTip('get_data_failed_key',true);
          //  this.router.navigateByUrl('/login');
          this.router.navigate(['/login']);

        }
        //  let errDetail = err.eror;
        //  console.log(errDetail);
        return Observable.throw(new Error(err.toString()));
      })
  }
  // 封装GET
  get(url, isAuth) {
    const isMedical = url.indexOf('medicalSatisfactionInspection') !== -1 ? true : false;

    let headers = new HttpHeaders();
    if (isAuth && !isMedical) {
      headers = new HttpHeaders().set('token', this.localStorageService.getStore('token'));
    }
    let fullUrl = this.urlPrefix + url;
    // let fullUrl = url;

    return this.http.get(fullUrl, { headers: headers })
    .timeout(TIME_OUT)
    .catch((err: Response) => {
      if (err.status == 401 || err.status === undefined) {
        this.localStorageService.removeStore('token')
          //  this.appService.toastTip('get_data_failed_key',true);
          //  this.router.navigateByUrl('/login');
          this.router.navigate(['/login']);

        }
        //  let errDetail = err.eror;
        //  console.log(errDetail);
        return Observable.throw(new Error(err.toString()));
      })
    //  .do(
    //    data => {
    //      console.log('data')
    //      console.log( Response.toString());
    //      console.log(data)
    //    }
    //  )

  }
  // 封装带入URL的GET，目前用于验证APP输入服务器端URL正确性
  getWithUrl(url, isAuth) {
    let headers = new HttpHeaders();
    if (isAuth) {
      headers = new HttpHeaders().set('token', this.localStorageService.getStore('token'));
    }
    let fullUrl = url;
    return this.http.get(fullUrl, { headers: headers })
    .timeout(TIME_OUT)
    .catch((err: Response) => {
      if (err.status == 401 || err.status === undefined) {
        this.localStorageService.removeStore('token')
          //  this.appService.toastTip('get_data_failed_key',true);
          //  this.router.navigateByUrl('/login');
          this.router.navigate(['/login']);

        }
        //  let errDetail = err.eror;
        //  console.log(errDetail);
        return Observable.throw(new Error(err.toString()));
      })
    //  .do(
    //    data => {
    //      console.log('data')
    //      console.log( Response.toString());
    //      console.log(data)
    //    }
    //  )

  }
  // 验证APP输入服务器端URL正确性
  systemInformationVersion(url: string): any {
    //  const params = new HttpParams()
    //  .set('codes', codes)
    return this.getWithUrl(url + '/system-information/version', false);
  }
  checkVersion(para): any {
    return this.get('/configurations/' + para, false);
  }
  // 登录
  permissionsLogin(userName: string, password: string, verificationCode: string, proof: string): any {
    return this.post('/permissions/login', { userName, password, verificationCode, proof }, false);
  }
  getVerificationCode(): any {
    return this.get('/permissions/getVerifyCode', false);
  }
  // 查找Code的子Code
  codesChildrenOf(codes): any {
    return this.get('/codes/childrenOf?codes=' + codes, true);
  }
  // 使用Like查找Code列表
  codesAllList(codeLike): any {
    var obj = {
      'pagingTool':
      {
        'currentPage': 0, 'pageSize': 1000
      },
      'queryCriterias':
      [
      {
        'connection': 'and', 'key': 'code', 'condition': 'like', 'value': codeLike, 'isValueADigital': false
      }
      ]
    };
    return this.post('/codes/all/list', obj, true);
  }

  // 公告列表
  announcementListUser(para): any {
    return this.post('/announcement/list', para, true);
  }
  // 设置公告已读
  announcementRead(para): any {
    return this.put('/announcement/read', para, true);
  }
  // 消息列表
  messageListSinotrans(para): any {
    return this.post('/message/list/sinotrans', para, true);
  }
  //消息列表 2）
  messageList(para): any {
    return this.post('/message/list', para, true);
  }
  //更新消息已读
  updateMessageRead(para): any {
    return this.get('/message/read/' + para, true);
  }
  //设置消息已读
  updateMessage(para): any {
    return this.put('/message', para, true);
  }

  //设置消息已读 -新
  updateReadMessage(ids): any {
    return this.get('/message/read/' + ids, true);
  }

  readAllMessage(type): any {
    return this.get(type ? '/message/readAll?type=' + type : '/message/readAll', true);
  }

  getMessageDetail(id): any {
    return this.get('/message/' + id, true);
  }

  //获取未读消息数
  getNewMessageCount(): any {
    return this.get('/message/NewMessageNumber?type=_SYS_MESSAGE_A', true);
  }
  //获取未读通知数
  getNewNotificationCount(): any {
    return this.get('/message/NewMessageNumber', true);
  }
  // 任务中心列表(用于待办和历史)
  taskList(para): any {
    return this.post('/tasks/list', para, true);
  }
  // 任务中心列表(用于待定任务)
  penddingTaskofMine(): any {
    return this.get('/inspections/pendingTaskOfMine', true);
  }
  inspectionsTask(id): any {
    return this.get('/inspections/task/' + id, true);
  }
  activityById(activity_id): any {
    return this.get('/activities/' + activity_id, true);
  }
  activitiesInitData(): any {
    return this.get('/activities/initData', true);
  }
  getInspectionsObject(id): any {
    return this.get('/inspections/object/' + id, true);
  }
  // 添加执行检查对象
  addInspectionsObject(para, reference_id): any {
    return this.post('/inspections/' + reference_id + '/object', para, true);
  }
  // 修改执行检查对象
  updateInspectionsObject(para): any {
    return this.put('/inspections/object', para, true);
  }
  // 删除执行检查对象
  deleteInspectionsObject(id): any {
    return this.delete('/inspections/object/?id=' + id, true);
  }
  // 获取执行检查对象列表
  inspectionsObjectList(reference_id): any {
    var obj = {
      'pagingTool': {
        'currentPage': 1,
        'pageSize': 1000
      }
    };
    return this.post('/inspections/' + reference_id + '/object/list', obj, true);
  }

  correctiveActionList(deviation_id): any {
    let para = { 'pagingTool': { 'currentPage': 1, 'pageSize': 1000 }, 'additionalParams': { 'deviation_type': '_SYS_B1_2' } };
    return this.post('/deviations/' + deviation_id + '/correctiveAction/list', para, true);
  }
  correctiveActionListNoRestr(deviation_id): any {
    let para = { 'pagingTool': { 'currentPage': 1, 'pageSize': 1000 } };
    return this.post('/deviations/' + deviation_id + '/correctiveAction/list', para, true);
  }

  getProjectManagers(orgId): any {
    let para = { 
      'pagingTool': { 'currentPage': 1, 'pageSize': 10 },
      'queryCriterias': [
      {
        'connection': 'and', 'key': 'org_ids', 'condition': '=', 'value': orgId, 'isValueADigital': false
      },
      {
        'connection': 'and', 'key': 'titles', 'condition': '=', 'value': '_SYS_CB_15', 'isValueADigital': false
      }
      ]
    };
    return this.post('/employees/autocompletelist/titles', para, true);
  }
  
  getProjectManagersTitle(params): any {
    return this.post('/employees/autocompletelist/titles', params, true);
  }

  // 获取任务表单详情
  formsIdPreview(para): any {
    return this.get('forms/' + para + '/preview', true);
  }

  // 获取当前用户menus list
  getUserMenusList():any{
    // const a = {
    //   DATA: []
    // };
    // a.DATA = [
    //   'add.report', 'add.report.hidden', 'add.report.immediatelyCheck', 'recordList',
    //   'recordList.hidden', 'recordList.hidden.list', 'recordList.hidden.rectificationList',
    //   'recordList.check', 'recordList.check.list', 'recordList.check.rectificationList'
    // ];
    return this.get('/menus/list', true);
    // return of(a);
  }

  employeesListAll(): any {
    return this.post('/employees/list/all', null, true);
  }

  employeesList(para): any {
    return this.post('/employees/list', para, true);
  }

  corporatesDeviation(id): any {
    return this.get('/corporates/deviation/' + id, true);
  }

  // 执行整改措施详情
  correctiveActionsTask(id): any {
    return this.get('/correctiveActions/task/' + id, true);
  }
  // 执行整改不符合项详情
  deviationsTask(id): any {
    return this.get('/deviations/task/' + id, true);
  }
  // 获取公司信息
  corporateById(department): any {
    return this.get('/corporates/' + department, true);
  }

  inspectionsReport(id): any {
    const para = {};
    return this.post('/inspections/' + id + '/report', para, true);
  }

  // 检查查询
  inspectionsRecordsList(para): any {
    return this.post('/inspections/record/list', para, true);
  }

  // 检查整改措施记录
  correctiveActionsRecordsList(para): any {
    return this.post('/correctiveActions/records/list', para, true);
  }

  hiddenDangerRecordsList(para): any {
    return this.post('/hiddenDangerReport/list', para, true);
  }

  // 删除图片
  deleteFile(id): any {
    return this.delete('/file?id=' + id, true);
  }
  // 获取图片
  getFile(id): any {
    return this.get('/file/' + id, true);
  }

  getConfig(value): any {
    return this.get('/configurations/' + value, true);
  }

  corporates(id): any {
    return this.get('/corporates/' + id, true);
  }
  userById(user_id): any {
    return this.get('/users/' + user_id, true);
  }
  corporatesOrgs(): any {
    return this.get('/corporates/orgs/', true);
  }

  taskById(id): any {
    return this.get('/tasks/' + id, true);
  }

  inspectionsCompletes(para, id): any {
    return this.post('/inspections/completes/' + id, para, true);
  }

  // 上传图片
  uploadFile(para): any {
    return this.post('/file', para, true);
  }

   // 上传图片
  uploadFileApp(para): any {
    return this.post('/file/app', para, true);
  }

  // 个人EHS绩效考核表
  personalEHSReport(para): any {
    return this.post('/correctiveActions/report/personalEHSReport', para, true);
  }

  // 检查报表
  investigationReport(para): any {
    return this.post('/correctiveActions/report/investigationReport', para, true);
  }

  // 培训报表
  trainingReport(para): any {
    return this.post('/correctiveActions/report/trainingReport', para, true);
  }

  // 获取跟用户相关的组织架构
  listMinByUser(para): any {
    return this.post('/corporates/listMinByUser', para, true);
  }

  // 钉钉登录
  ddLogin(code: string): any {
    return this.get(`/permissions/login/${code}`, false);
  }

  // 根据父组织架构获取子组织架构
  getCorporateByParent(id): any {
    return this.get(`/corporates/${id}/children`, true);
  }

  // 获取公告html内容
  getContentHtml(id): any {
    return this.get('/announcement/' + id, true);
  }

  // 隐患列表
  rectificationList(para): any {
    return this.post('/hiddenDangerReport/list', para, true);
  }

  // 获取任务数量
  getMyTaskCount(): any {
    return this.get('/app/firstPage/myTasks/count', true);
  }

  // 获取任务列表
  getMyTaskList(param): any {
    return this.post('/app/firstPage/myTasks/list', param, true);
  }

  // 获取当前项目信息
  getCurrentProjectInfo(param): any {
    return this.post('/app/firstPage/projectInfo', param, true);
  }

  // 获取首页隐患检查数据
  getProjectChart(orgId): any {
    return this.post('/app/firstPage/projectChart/' + orgId, null, true);
  }

  // 获取公告列表
  getAnnouncementList(param): any {
    return this.post('/announcement/list/user', param, true);
  }

  // 获取项目成本中心代码列表
  getProjectCostCenterCodeList(param): any {
    return this.post('/corporates/allList', param, true);
  }

  // 获取项目成本中心代码列表
  getProjectCostCenterCodeListTable(param): any {
    return this.post('/corporates/list/table', param, true);
  }

  // 获取人员列表
  getStaffList(param): any {
    let passParams = JSON.parse(JSON.stringify(param));
    // passParams.params = {
    //   needJobEvaluationTime: true
    // };
    return this.post('/employees/autocompletelist', passParams, true);
  }
  getCalendarIons(date): any {
    let parame =  {
      'pagingTool': {
        'currentPage': 1,
        'pageSize': 10000
      },
      'queryCriterias': [{
        'connection': 'and',
        'key': 'end_dtm',
        'condition': '<=',
        'value': '2030-03-13T15:59:00.000Z',
        'isValueADigital': false
      }]
    };
    return this.post('/training/trainRecords/calendar', parame, true);
  }
  // 获取任务详情 basicInfo
  getIncidentTaskBasicById(taskId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get(`/tasks/${taskId}/basicInfo`, true).subscribe(res => {
        res && res['STATUS'] === 0 ? resolve(res) : reject(res);
      });
    });
  }

  getEmployeesDivisionalList(param): Promise<any> {
    return new Promise((resolve, reject) => {
      this.post(`/employees/divisionalList`, param, true).subscribe(res => {
        res && res['STATUS'] === 0 ? resolve(res) : reject(res);
      });
    });
  }

  // 获取历史任务列表
  getPersonalHistoryTaskList(param): any {
    return this.post('/tasks/taskCenterPersonalHistory', param, true);
  }

  // 获取待办事项列表
  getPersonalTaskList(param): any {
    return this.post('/tasks/taskCenterPersonal', param, true);
  }

  // 获取待领取任务列表
  getPendingTaskList(param): any {
    return this.post('/tasks/taskCenterPending', param, true);
  }

  // 获取执行整改任务详情 taskInfo
  getTaskInfoById(id): any {
    return this.get('/tasks/correctiveAction/' + id, true);
  }

  // 获取执行整改任务详情 detailedInfo
  getTaskDetailInfoById(id): any {
    return this.get('/incidentCorrectiveActions/detailedInfo/' + id, true);
  }

  // 获取执行整改任务相关任务信息 relatedTaskInfo
  getRelatedTaskInfoByTaskId(id): any {
    return this.get('/tasks/correctiveAction/refTask/' + id, true);
  }

  // 获取执行整改任务事故信息 correctiveIncidentInfo
  getIncidentInfoByTaskId(id): any {
    return this.get('/incidents/getByCorrectiveTaskId/' + id, true);
  }
  // 获取执行整改任务详情整改信息 taskContent
  getCorrectiveActionInfo(param): any {
    return this.post('/tasks/correctiveAction/content/list', param, true);
  }

  // 新增执行整改
  saveCorrectiveActionContent(param): any {
    return this.post('/tasks/correctiveAction/content/update', param, true);
  }

  // 更新执行整改
  updateCorrectiveActionContent(param): any {
    return this.put('/tasks/correctiveAction/content/update', param, true);
  }

  // 提交执行整改
  submitCorrectiveActionExecution(taskId, param): any {
    return this.put('/tasks/correctiveAction/' + taskId + '/verify', param, true);
  }

  // 获取验证整改信息
  getCorrectiveActionVerificationInfo(taskId): any {
    return this.get('/tasks/correctiveAction/' + taskId + '/investigationVerification', true);
  }

  // 保存验证整改信息
  updateCorrectiveActionVerificationInfo(param): any {
    return this.post('/tasks/correctiveAction/investigationVerification', param, true);
  }

  // 提交验证整改信息
  submitCorrectiveActionVerification(param, taskId): any {
    return this.post('/taskSignoff/task/' + taskId + '/submit', param, true);
  }
  // 21.1.4 隐患列表
  getDeviationsList(param): any {
    return this.post('/deviations/list', param, true);
  }

  // 隐患详情
  getDeviationsDetailByTaskId(id): any {
    return this.get('/deviations/' + id, true);
  }

  // 隐患的整改措施列表
  getInspectionCorrectiveActionTasks(id): any {
    return this.get('/deviations/' + id + '/inspectionCorrectiveActionTasks', true);
  }

  // 检查查询列表
  getInspectionTasks(id): any {
    return this.get('/deviations/' + id + '/inspectionTasks', true);
  }
  // 提交隐患上报
  submitHiddenReporting(param): any {
    return this.post('/inspections/issuereporting', param, true);
  }

  // 检查查询
  getInspectionTasksList(param): any {
    return this.post('/inspectionTasks/list', param, true);
  }
  // 检查查询详情
  TaskInfoValue(id): any {
    return this.get('/inspectionTasks/' + id + '/detailedInfo', true);
  }

  TaskBasicById(id): any {
    return this.get('/tasks/' + id + '/basicInfo', true);
  }

  getClaimableFlag(id): any {
    return this.get('/inspectionTasks/' + id + '/claimable', true);
  }

  // 认领
  claimInspectionTasksList(param, id): any {
    return this.put('/inspectionTasks/' + id + '/assignee', param, true);
  }

  getTaskInfoList(id): any {
    return this.get('/inspectionTasks/' + id + '/deviations', true);
  }

  getTaskInfoGeneralList(id): any {
    return this.get('/inspectionTasks/' + id + '/inspectionObjectSummary', true);
  }

  // 保存任务内容
  saveInspectionTasksList(param, id): any {
    return this.put('/inspectionTasks/' + id + '/deviations', param, true);
  }

  // 提交一次复审
  submitInspectionTasksList(id): any {
    return this.post('/inspectionTasks/' + id + '/submit', null, true);
  }

  // 整改措施列表
  getRectificationMeasuresList(param): any {
    return this.post('/inspectionCorrectiveActions/task/list', param, true);
  }

  // 整改措施详情
  getRectificationDetail(id): any {
    return this.get('/inspectionCorrectiveActions/' + id + '/detailedInfo', true);
  }

  // 整改措施任务内容
  getContentDetail(id): any {
    return this.get('/tasks/correctiveAction/' + id + '/content', true);
  }

  // 保存整改措施
  saveRecordTasksList(param): any {
    return this.put('/tasks/correctiveAction/inspectionContent', param, true);
  }

  submitRecordTasksList(id): any {
    return this.put('/tasks/correctiveAction/' + id + '/verify', null, true);
  }

  // 验证整改
  getInvestigationVerification(id): any {
    return this.get('/tasks/correctiveAction/' + id + '/investigationVerification', true);
  }

  // 保存验证资讯
  saveInvestigationVerification(param): any {
    return this.post('/tasks/correctiveAction/investigationVerification', param, true);
  }

  // 结案整改措施
  closeSubmitInspectionCA(id): any {
    return this.put('/tasks/correctiveAction/' + id + '/submitInspectionCA', null, true);
  }

  // 相关任务list
  getRelatedTaskExpanded(id): any {
    return this.get('/inspectionTasks/' + id + '/relatedCATask', true);
  }

  // 爱玛客-立即检查
  // 获取检查表格List
  getFormsList(param): any {
    return this.post('/forms/havingPreview/list', param, true);
  }

  // 获取检查表详情
  getFormDetail(id): any {
    return this.get('/forms/' + id + '/preview', true);
  }

  // 获取带大项的表格
  getFormDetailApp(id): any {
    return this.get('/forms/' + id + '/preview', true);
    // return this.get('/forms/' + id + '/preview/app', true);
  }

  // 创建立即检查
  createImmediateInspection(param): any {
    return this.post('/inspections', param, true);
  }

  // 创建立即检查-提交
  createImmediateInspectionSave(param): any {
    return this.post('/inspections/submit/app', param, true);
  }

  // 暂存立即检查
  saveImmediateInspection(param): any {
    return this.post('/inspections/save/app', param, true);
  }

  // 获取目前有的立即检查的草稿
  getImmediateInspectionDraft() {
    return this.get('/inspections/draft', true);
  }

  // 获取填写了的立即检查数据 + 计划检查
  getAdHocInspection(id): any {
    return this.get('/inspectionTasks/' + id + '/adHocInspection', true);
  }

  // 获取填写了的立即检查数据
  getAdHocInspectionApp(id): any {
    return this.get('/inspectionTasks/' + id + '/adHocInspection/app', true);
  }

  // 获取我的课程list
  getMyClassList(params): any {
    return this.post('/training/trainRecords/rule/list', params, true);
  }

  // 获取课程详情
  getLessonById(id): any {
    return this.get('/training/lessons/' + id, true);
  }

  // 根据id获取课程任务详情
  getLessonTaskById(id,lessonId): any {
    return this.get('/training/trainRecords/lessonTask/' + id + '/' + lessonId, true);
  }
  // 计划检查api
  // 获取计划检查基础信息
  getBasicInfoPlanInspection(id): any {
    return this.get('/inspectionTasks/' + id + '/scheduled/detailedInfo', true);
  }

  // 获取计划检查任务的表格list
  getPlanInspectionFormList(id): any {
    return this.get('/inspectionTasks/' + id + '/formList', true);
  }

  // 保存计划检查表格数据
  updatePlanFormData(id, param): any {
    return this.put('/inspectionTasks/' + id + '/inspectionForms', param, true);
  }

  // 提交计划检查
  submitPlanInspection(id): any {
    return this.post('/inspectionTasks/' + id + '/submit', null, true);
  }

  // 获取企业微信jssdk配置
  getWechatJSSDKConfig(): any {
    return this.get('/permissions/info/weChart', true);
  }

  getInfoConfig(): any {
    return this.post('/permissions/info', null, true);
  }

  // 获取当前用户的默认组织架构
  getDefaultOrg():any {
    return this.get('/corporates/list/table/default/all', true);
  }

  getDefaultOrgByBL(bl):any {
    return this.get('/corporates/list/table/default/' + bl, true);
  }

  // 修改密码
  changePassword(pwd) {
    return this.put('/users/changePW', pwd, true)
  }

  getTaskCalenderList(param): any{
    return this.post('/training/trainRecords/calendar/app', param, true);
  }
    
  // 提交转派的调查报告
  submitTransferCorrective(param): any {
    return this.put('/tasks/correctiveAction/transfer', param, true);
  }
  
  // 隐患上报是允不允许用户自己选一次复审人是通过后端的这个接口里的配置来做判断的
  getAllConf():any {
    return this.get('/configurations/getAllConf', true);
  }
    
  // 获取是否有最优实践
  getHasOptimal(): any {
    return this.get('/configurations/best-practice.enable', true);
  }
    
  getEmailDefaultName(formName, taskId): any {
    return this.get('/messageGroup/shareTask/users/' + formName + '/' + taskId, true);
  }

  // 提交发送邮件的人员
  submitMessageGroup(data): any{
    return this.post('/messageGroup/shareTask', data, true);
  }

  // 获取一次复审人下拉
  getFirstReviewer(data): any{
    return this.post('/employees/list/condition', data, true);
  }

    // 合规文件api
  // 合规文件
  getEditPageData(id): any{
    return this.get('/compliantDocument/editPageData/' + id, true);
  }

  // 获取合规文件详情
  getCompliantDocumentCategory(id): any{
    return this.get('/compliantDocumentCategory/' + id, true);
  }

  // 新建合规文件
  createCompliantDocument(data): any{
    return this.post('/compliantDocument', data, true);
  }

  // 更新合规文件
  updateCompliantDocument(data): any{
    return this.put('/compliantDocument', data, true);
  }


  // 获取政府检查详情
  getGovernmentDetail(taskId): any{
    return this.get('/inspections/government/' + taskId, true);
  }

  // 获取政府检查list
  getGovList(params): any{
    return this.post('/inspections/government/list', params, true);
  }

  // 获取政府检查list
  getGovCorrectiveList(params): any{
    return this.post('/inspectionCorrectiveActions/task/list', params, true);
  }
  // 组织架构层级结构
  getCorporatesChildrenById(id): any{
    return this.get('/corporates/getChildrenWithoutInvalid/' + id, true);
  }  
  // 政府检查上报api
  // 创建政府检查
  createGovReportSave(param): any {
    return this.put('/inspections/government/submit', param, true);
  }

  // e-learning
  // 获取上传后的url
  getAliyunFileUrl(videoId): any {
    return this.get('/aliyun/playInfo/' + videoId, true);
  }

  // 视频轮调
  updataELTask(params): any {
    return this.put('/elTaskResponse', params, true);
  }

  submitExamPaperList(id1,id2,para,time,isAuto): any {
    return this.post('/testPaper/submit4CertificationAssessment/' + id1 + '/' + id2 + '/' + time + '/' + isAuto, para, true);
  }

  submitTestPaperList(id1,id2,para): any {
    return this.post('/testPaper/submit/' + id1 + '/' + id2, para, true);
  }

  getTestPaperList(id1,id2,id3): any {
    return this.get('/testPaper/getByCombinationConditions/' + id1 + '/' + id2 + '/' + id3 , true);
  }

  getExamPaperList(id1,id2,id3): any {
    return this.get('/testPaper/getInfo4CertificationAssessment/' + id1 + '/' + id2 + '/' + id3 , true);
  }

  queryForm(query: string, excludeMedical?: boolean) {
    const queryUtils = {
      pagingTool: {
        currentPage: 1,
        pageSize: 35
      },
      queryCriterias: [],
      queryOrderBies: [
        {
          columnName: 'id',
          orderType: 'desc'
        }
      ]
    };
    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      // ...(query ? [new QueryCriteria('formName', query, 'like')] : []),
    ];
    // if (excludeMedical) {
    //   queryUtils.queryCriterias.push(...([new QueryCriteria('serviceType', '_SYS_WH_14_1', '=')]));
    // }
    return this.post('/forms/havingPreview/list', queryUtils, true);
  }

  queryOrgCompany(query: string, orgId?: string, type?: string) {
    if (type == "_SYS_A8_2") orgId = null;
    if (type === '_SYS_A8_5' && !orgId) orgId = null;
    const queryUtils = this.generateQueryUtil(type, query, orgId);
    return this.post('/corporates/list/table/dashboard', queryUtils, true);
  }

  generateQueryUtil(orgType, queryInput?, parentId?) {
    const queryUtils = {
      pagingTool: {
        currentPage: 1,
        pageSize: 100
      },
      queryCriterias: [],
      queryOrderBies: [
      {
        columnName: 'code',
        orderType: 'asc'
      }
      ]
    };

    if (queryInput) {
      queryUtils.queryCriterias.push({
        connection: 'and', 
        key: 'code', 
        condition: 'like', 
        value: queryInput, 
        isValueADigital: false
      });
    }
    if (orgType) {
      queryUtils.queryCriterias.push({
        connection: 'and', 
        key: 'org_type', 
        condition: 'like', 
        value: orgType, 
        isValueADigital: false
      });
    }
    if (parentId) {
      queryUtils.queryCriterias.push({
        connection: 'and', 
        key: 'parent_id', 
        condition: 'in', 
        value: parentId, 
        isValueADigital: false
      });
    }

    return queryUtils;
  }

  // 根据类型获取组织架构
  queryOrg(query: string, orgId?: string, type?: string) {
    if (type == '_SYS_A8_2') orgId = null;
    const queryUtils = this.generateQueryUtil(type, query, orgId);
    return this.post('/corporates/list/table', queryUtils, true);
  }

  getEmployeeByOrgId(searchMsg, id) {
    const queryUtil = this.generateBasicQueryUtil();
    if (id) {
      queryUtil.queryCriterias.push({
        connection: 'and',
        key: 'org_ids',
        condition: '=',
        value: id,
        isValueADigital: false
      });
    }
    if (searchMsg) {
      queryUtil.queryCriterias.push({
        connection: 'and',
        key: 'code',
        condition: 'like',
        value: searchMsg,
        isValueADigital: false
      });
    }
    return this.post('/employees/autocompletelist', queryUtil, true);
  }

  generateBasicQueryUtil() {
    return {
      pagingTool: {
        currentPage: 1,
        pageSize: 20
      },
      queryCriterias: [],
      queryOrderBies: []
    }
  }
}
