import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../../service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController, PickerController } from '@ionic/angular';
import { PickerOptions } from "@ionic/core";
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
import { NewPageSelectComponent } from '../../shared/components/new-page-select/new-page-select.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-find-dialog',
  templateUrl: './add-find-dialog.component.html',
  styleUrls: ['./add-find-dialog.component.scss'],
})
export class AddFindDialogComponent implements OnInit {
  staffList = [];
  genderList = [];
  injuredPartsList = [];
  classificationList = [];
  individualInfo: any = {};
  currentEmployeeAge = 0;
  injuredPartsDisplay = '';
  projectCostCenterCodeList = [];
  selectedProjectCostCenter;
  isInvestigation: boolean;
  org;
  insurance = [];  // 保险情况下拉code
  editable = false;
  attachIds = '';
  basicInfoAttachments;
  reviewClick;
  isShowDel;

  constructor(
    public dataService: DataService,
    public appService: AppService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalController: ModalController,
    public pickerController: PickerController,
    private translateService: TranslateService,
    public localStorageService: LocalStorageService) {
    let params = this.navParams.data;
    console.log('add-find-dialog中的params', params)
    // this.staffList = params.staffList;
    this.org = params.org;
    this.isInvestigation = params.isInvestigation;
    this.individualInfo = params.currentData ? params.currentData : {};
    this.isShowDel = params.flag ? params.flag : 'add';
    this.reviewClick = params.reviewClick ? params.reviewClick : false;
    if (this.individualInfo.attachments) {
      this.basicInfoAttachments = '';
      this.individualInfo.attachments.forEach(file => {
        this.basicInfoAttachments += (file.fileId + ',');
      });
      if (this.basicInfoAttachments) {
        this.basicInfoAttachments = this.basicInfoAttachments.substr(0, this.basicInfoAttachments.length - 1);
      }
    }
    this.editable = params.editable ? params.editable : false;
    console.log('find-dialog', this.org);
    if (!this.individualInfo.displayObjectName) {
      this.individualInfo.displayObjectName = this.org.full_name;
    }

    const that = this;
    window.onpopstate = function () {
      const a = document.getElementsByTagName('app-add-find-dialog');
      if (a.length > 0) {
        setTimeout(() => {
          that.modalController.dismiss();
          // window.history.forward();
        }, 100)
      }
    };
  }

  ionViewDidEnter() {
    this.ngOnInit();
  }

  ngOnInit() {
    // 设置标题
    document.getElementsByTagName('title').item(0).innerText = this.translateService.instant('aramark_hidden_danger');
    if (dd.env.platform !== 'notInDingTalk') {
      dd.biz.navigation.setTitle({
        title: this.translateService.instant('aramark_hidden_danger') // 钉钉设置导航栏标题
      });
    }
    this.getInjuredPartsList();
    this.getClassificationList();
    this.getInsuranceList();
  }

  // 风险级别
  async getInjuredPartsList(): Promise<any> {
    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.codesChildrenOf('_SYS_AX').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.injuredPartsList = res.DATA[0].childCodes;
          resolve();
        } else {
          this.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  // 获取受伤部位列表
  async getClassificationList(): Promise<any> {
    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.codesChildrenOf('_SYS_DC').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.classificationList = res.DATA[0].childCodes;
          resolve();
        } else {
          this.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  async selectInjuredParts() {
    if (!this.editable) {
      const modal = await this.modalController.create({
        component: NewPageSelectComponent,
        componentProps: {
          pageTitle: this.translateService.instant('aramark_inspection_risk_level'),
          placeholderMessage: this.translateService.instant('aramark_inspection_risk_level'),
          originalOptionList: this.injuredPartsList,
          displayField: 'code_desc_zh',
          valueField: 'code',
          multiSelect: false,
          needTranslation: false,
          cancelText: 'aramark_cancel',
          confirmText: 'aramark_confirm',
          selected: this.individualInfo.riskLevel ? this.individualInfo.riskLevel : [],
          needShowColor: true
        },
        showBackdrop: true,
        backdropDismiss: true
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = this.translateService.instant('aramark_hidden_danger');
        if (dd.env.platform !== 'notInDingTalk') {
          dd.biz.navigation.setTitle({
            title: this.translateService.instant('aramark_hidden_danger') // 钉钉设置导航栏标题
          });
        }
        if (res.data) {
          this.individualInfo.riskLevel = res.data;
        }
      });
      return await modal.present();
    }
  }

  // 隐患分类
  async selectClassification() {
    if (!this.editable) {
      const modal = await this.modalController.create({
        component: NewPageSelectComponent,
        componentProps: {
          pageTitle: this.translateService.instant('hidden_danger_category_key'),
          placeholderMessage: this.translateService.instant('hidden_danger_category_key'),
          originalOptionList: this.classificationList,
          displayField: 'code_desc_zh',
          valueField: 'code',
          multiSelect: false,
          needTranslation: false,
          cancelText: 'aramark_cancel',
          confirmText: 'aramark_confirm',
          selected: this.individualInfo.classification ? this.individualInfo.classification : []
        },
        showBackdrop: true,
        backdropDismiss: true
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = this.translateService.instant('aramark_hidden_danger');
        if (dd.env.platform !== 'notInDingTalk') {
          dd.biz.navigation.setTitle({
            title: this.translateService.instant('aramark_hidden_danger') // 钉钉设置导航栏标题
          });
        }
        if (res.data) {
          this.individualInfo.classification = res.data;
        }
      });
      return await modal.present();
    }
  }

  async cancel() {
    await this.modalController.dismiss();
  }

  async save() {
    if (!this.requiredFieldsCompleted()) {
      return;
    }

    const array = this.basicInfoAttachments.split(',');
    this.individualInfo.attachments = [];
    array.forEach(v => {
      // const f = this.individualInfo.attachments.find(i => parseInt(i.fileId) === parseInt(v));
      // if (!f || f.length === 0) {
        this.individualInfo.attachments.push({ fileId: v });
      // }
    });
    console.log(this.individualInfo);
    await this.modalController.dismiss(this.individualInfo);
  }

  requiredFieldsCompleted() {
    if (this.individualInfo.displayObjectName && this.individualInfo.finding && this.basicInfoAttachments) {
      return true;
    }
    return false;
  }

  async getInsuranceList(): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      const that = this;
      that.dataService.codesChildrenOf('_SYS_WH_9').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.insurance = res.DATA[0].childCodes;
          resolve();
        } else {
          that.appService.toastTip('get_data_failed_key', true);
          reject();
        }
      });
    });
    return promise;
  }

  translateCode(code, translationList: any[]) {
    for (const item of translationList) {
      if (code === item.code) {
        return item.code_desc_zh;
      }
    }
    return '';
  }

  // 更新选择的事故日期格式
  changeIncidentDateFormat(event) {
    let selectedDate = event.detail.value;
    if (selectedDate) {
      this.individualInfo.returnToWorkDate = new Date(selectedDate).toISOString();
    }
  }

  // 上传附件
  updateIncidentReportPictures(files) {
    console.log(files);
    this.attachIds = files;
    this.basicInfoAttachments = files;
    // const array = this.attachIds.split(',');
    // this.individualInfo.attachments = this.individualInfo.attachments ? this.individualInfo.attachments : [];
    // array.forEach(v => {
    //   const f = this.individualInfo.attachments.find(i => parseInt(i.fileId) === parseInt(v));
    //   if (!f || f.length === 0) {
    //     this.individualInfo.attachments.push({ fileId: v });
    //   }
    // });
  }

  // 删除隐患
  deletePartyIndividual() {

  }
}
