import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../../../../service';
import { Router } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController, PickerController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi

import { PickerOptions } from "@ionic/core";

import { OrgSelectModalComponent } from '../org-select-modal/org-select-modal.component';
import { PersonSelectModalComponent } from '../person-select-modal/person-select-modal.component';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';


@Component({
	selector: 'inspection-filter-modal',
	templateUrl: './task-filter-modal.component.html',
	styleUrls: ['./task-filter-modal.component.scss'],
})
export class InspectionQPEFilterModalComponent implements OnInit {
  queryCriterias = [];
  query;
  queryKey = {
    bl: 'businessLine',
    region: 'org_operation',
    area: 'org_area',
    program: 'org_program',
  }

	// 默认筛选条件
  defaultBL = null;
  defaultDivision = null;
  defaultRegion = null;
  defaultProject = null;
  startTime = null;
  endTime = null;
  defaultCheckTable = null;
  courseType = null;
  maintanenceDept = null;

  needFormSelection;

  courseTypeList = [];
  courseTypeListTrans = [];
  maintenanceDeptList = [];
  maintenanceDeptListTrans = [];

  // checkTypeTableList = [];
  // checkTypeTable = {
  //   type: '',
  //   table: '',
  // };
  // selectCheckTypeTableValue;

  currentDateString = new Date().toISOString();

  // 选表的名字
  inspectionForm;
  // 选表要传递出的数据,第一个是type，第二个是id
  selectCheckTable = [];
  // 选表的数据源
  formPickerDate = [];

  queryCompany;

  isQPE;

  queryForm;

  serviceTypeList = [
    { code: '_SYS_WH_15_1', name: '环境服务' },
    { code: '_SYS_WH_15_2', name: '运送服务' },
    { code: '_SYS_WH_15_3', name: '设施服务' },
    { code: '_SYS_WH_15_4', name: '安保服务' },
  ];

  taskStatusList = [
    { code: '_SYS_PL1_8', name: '草稿' },
    { code: '_SYS_PL1_E', name: '整改' },
    { code: '_SYS_PL1_7', name: '关闭' },
  ]

	constructor(
		public dataService: DataService,
		public appService: AppService,
		public router: Router,
		public navCtrl: NavController,
		public navParams: NavParams,
		public modalController: ModalController,
    public localStorageService: LocalStorageService,
    public pickerController: PickerController,
  ) {
		let params = this.navParams.data;
    this.query = params.query;
    this.isQPE = params.qpe;
	}

	ngOnInit() {
		// 设置标题
		document.getElementsByTagName('title').item(0).innerText = '筛选条件';
	}

	// 重置条件
	resetSearchFilters() {
    Object.keys(this.query).forEach((item, index) => {
      this.query[item] = null;
    });
	}

	cancel() {
		this.modalController.dismiss();
	}

	confirm() {
		this.modalController.dismiss({ query: this.query });
  }
  
  async selectOrg(org, type, selectedOrg) {
    if(!org && (type !== "bl" && type !== 'program')){
      this.appService.toastTip('必须先选择上一级组织架构', true);
      return;
    };
    const modal = await this.modalController.create({
			component: OrgSelectModalComponent,
			showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        org: org,
        type: type,
        selectedOrg: selectedOrg,
      }
		});
		modal.onDidDismiss().then(res => {
      document.getElementsByTagName('title').item(0).innerText = '筛选条件';
      if (res.data) {
        this.query[this.queryKey[type]] = res.data.org;
        // 返回了数据，代表点击了确认键，进行数据处理
        // 如果选择了业务线，则清除大区、区域、项目的数据
        if (type == "bl") {
          this.query['org_operation'] = null;
          this.query['org_area'] = null;
          this.query['org_program'] = null;
        }
        // 如果选择了大区，则清除区域、项目的数据
        if (type == "region") {
          this.query['org_area'] = null;
          this.query['org_program'] = null;
        }
        // 如果选择了区域，则清除项目的数据
        if (type == "area") {
          this.query['org_program'] = null;
        }
      }
		});
		return await modal.present();
  }

  orgProcess(org) {
    return '[' + org.code + '] ' + org.full_name;
  }

  // 更新开始时间格式
	changeStartTimeFormat(event) {
		let selectedDate = event.detail.value;
		if (selectedDate) {
      if (this.endTime && new Date(selectedDate).getTime() - new Date(this.endTime).getTime() > 0) {
        this.appService.toastTip('开始时间不能大于结束时间', true);
      } else {
        this.startTime = new Date(selectedDate).toISOString();
      }
		}
	}
  // 更新结束时间格式
	changeEndTimeFormat(event) {
		let selectedDate = event.detail.value;
		if (selectedDate) {
      if (this.startTime && new Date(selectedDate).getTime() - new Date(this.startTime).getTime() < 0) {
        this.appService.toastTip('结束时间不能小于开始时间', true);
      } else {
        this.endTime = new Date(selectedDate).toISOString();
      }
		}
  }

  async selectServiceType() {
		let i = 0;
		if (this.query.serviceType) {
			this.serviceTypeList.forEach((res, index) =>{
				if (res.code === this.query.serviceType) {
					i = index;
					return;
				}
			})
		}
		let options: PickerOptions = {
			buttons: [
        {
          text: '取消',
          role: 'cancel'
        },
        {
          text: '确认',
          handler: (value: any) => {
            this.query.serviceType = value.serviceType.value;
          }
        }
      ],
      columns: [
        {
          name: 'serviceType',
          options: this.getServiceTypeOptionList(),
          selectedIndex: i
        }
			]
		};

		let picker = await this.pickerController.create(options);
		return picker.present();
  }
  
  getServiceTypeOptionList() {
		let options = [];
		this.serviceTypeList.forEach(item => {
			options.push({text: item.name, value: item.code});
		});
		return options;
  }

  async selectTaskStatus() {
		let i = 0;
		if (this.query.status) {
			this.taskStatusList.forEach((res, index) =>{
				if (res.code === this.query.status) {
					i = index;
					return;
				}
			})
		}
		let options: PickerOptions = {
			buttons: [
        {
          text: '取消',
          role: 'cancel'
        },
        {
          text: '确认',
          handler: (value: any) => {
            this.query.status = value.taskStatus.value;
          }
        }
      ],
      columns: [
        {
          name: 'taskStatus',
          options: this.getTaskStatusOptionList(),
          selectedIndex: i
        }
			]
		};

		let picker = await this.pickerController.create(options);
		return picker.present();
  }
  
  getTaskStatusOptionList() {
		let options = [];
		this.taskStatusList.forEach(item => {
			options.push({text: item.name, value: item.code});
		});
		return options;
  }
  
  employeeProcess(emp) {
    if (emp) {
      return '[' + emp.code + '] ' + emp.full_name;
    }
  }

  async selectInspector(selectedPerson) {
    const modal = await this.modalController.create({
			component: PersonSelectModalComponent,
			showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        selectPerson: selectedPerson,
        modalTitle: '评审人',
        source: 'auto',
        peopleInOrgId: '496'
      }
		});
		modal.onDidDismiss().then(res => {
      document.getElementsByTagName('title').item(0).innerText = '筛选条件';
      if (res.data) {
        this.query.inspector = res.data.person;
      }
		});
		return await modal.present();
  }

  async selectManager(selectedPerson) {
    const modal = await this.modalController.create({
			component: PersonSelectModalComponent,
			showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        selectPerson: selectedPerson,
        modalTitle: '项目经理',
        source: 'auto',
      }
		});
		modal.onDidDismiss().then(res => {
      document.getElementsByTagName('title').item(0).innerText = '筛选条件';
      if (res.data) {
        this.query.manager = res.data.person;
      }
		});
		return await modal.present();
  }
}
