import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../../../../service';
import { Router } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi

@Component({
	selector: 'org-select-modal',
	templateUrl: './org-select-modal.component.html',
	styleUrls: ['./org-select-modal.component.scss'],
})
export class OrgSelectModalComponent implements OnInit {
  queryCriterias = [];
  selectedOrg;
  selectedOrgId;
  parentId;
  parentOrg;
  type;
  // header = {
  //   bl: '业务线',
  //   region: '大区',
  //   area: '区域',
  //   program: '项目',
  // }
  // orgType = {
  //   bl: '_SYS_A8_2',
  //   region: '_SYS_A8_3',
  //   area: '_SYS_A8_4',
  //   program: '_SYS_A8_5',
  // }

  searchMessage = null;
  optionList = [];
  queryCompany;

	constructor(
		public dataService: DataService,
		public appService: AppService,
		public router: Router,
		public navCtrl: NavController,
		public navParams: NavParams,
		public modalController: ModalController,
    public localStorageService: LocalStorageService
  ) {
		let params = this.navParams.data;
    this.parentOrg = params.org;
    this.parentId = this.parentOrg ? this.parentOrg.id : null;
    this.type = params.type;
    this.queryCompany = params.queryCompany;
    this.selectedOrg = params.selectedOrg;
    // this.dataService.queryOrg(null, this.parentId, this.orgType[this.type]).subscribe(res => {
    //   if (res && res['STATUS'] === 0) {
    //     this.optionList = res['DATA'];
    //   }
    // });
    this.getOrg();
	}

	ngOnInit() {
		// 设置标题
		document.getElementsByTagName('title').item(0).innerText = '选择组织架构';
	}
  
  getOrg() {
    if (this.queryCompany && this.type === 'bl') {
      this.dataService.queryOrgCompany(this.searchMessage, this.parentId, null).subscribe(res => {
        if (res && res['STATUS'] === 0) {
          this.optionList = res['DATA'];
        }
      })
    } else {
      this.dataService.queryOrg(this.searchMessage, this.parentId, null).subscribe(res => {
        if (res && res['STATUS'] === 0) {
          this.optionList = res['DATA'];
        }
      })
    }
  }

  markSelected(org) {
    this.selectedOrg = org;
  }

  isSelected(org) {
    if (this.selectedOrg && org.id === this.selectedOrg.id) {
      return true;
    }
    return false;
  }

	cancel() {
		this.modalController.dismiss();
	}

	confirm() {
		this.modalController.dismiss({ org: this.selectedOrg });
	}
}
