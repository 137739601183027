import { EnvSelector } from './env-selector/env-selector';

let DEPLOY = '';

if (document.baseURI.toLowerCase().indexOf('localhost') !== -1) { // 本地或安卓
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('android') !== -1    // 安卓
   || userAgent.indexOf('iphone') !== -1     // iPhone
   || userAgent.indexOf('ipad') !== -1       // iPad
   || userAgent.indexOf('mac os') !== -1) {  // iPad
    switch (EnvSelector.env) {
      case 'dev':
      DEPLOY = 'dev';
      break;

      case 'demo':
      DEPLOY = 'v13demo';
      break;

      case 'stage':
      DEPLOY = 'dev';
      break;

      case 'prod':
      DEPLOY = 'dev';
      break;

      default:
      DEPLOY = 'dev';
      break;
    }
  } else { // 本地
    DEPLOY = 'dev';
  }
} else { // web端
  const a = document.baseURI.toLowerCase();
  if (a.indexOf('quantum-dev') !== -1) { // dev
    DEPLOY = 'dev';
  } else if (a.indexOf('quantum-stage') !== -1) { // stage
    DEPLOY = 'stage';
  } else if (a.indexOf('quantum-alp') !== -1) { // alpha
    DEPLOY = 'alpha';
  } else if (a.indexOf('v13demo') !== -1) {
    DEPLOY = 'v13demo';
  } else if (a.indexOf('praxair') !== -1) {
    DEPLOY = 'praxair';
  } else if (a.indexOf('bestex-v13-dev') !== -1) {
    DEPLOY = 'bestex-v13-dev';
  } else if (a.indexOf('bestex-v13-uat') !== -1) {
    DEPLOY = 'bestex-v13-uat';
  }
}

const FETCH = {
  'dev': {
    API_DOMAIN: 'https://quantum-dev.usequantum.com.cn',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://quantum-dev.usequantum.com.cn',
    IOS_VERSION: '1.1.8',
    ANDROID_VERSION: '1.1.8',
  },
  'stage': {
    API_DOMAIN: 'https://quantum-stage.usequantum.com.cn',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://quantum-stage.usequantum.com.cn',
    IOS_VERSION: '1.1.8',
    ANDROID_VERSION: '1.1.8',
  },
  'alpha': {
    API_DOMAIN: 'https://quantum-alp.logicsolutions.com.tw',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://quantum-alp.logicsolutions.com.tw',
    IOS_VERSION: '1.1.8',
    ANDROID_VERSION: '1.1.8',
  },
  'v13demo': {
    API_DOMAIN: 'https://v13demo.usequantum.com.cn',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://v13demo.usequantum.com.cn',
    IOS_VERSION: '1.1.8',
    ANDROID_VERSION: '1.1.8',
  },
  'praxair': {
    API_DOMAIN: 'https://praxair.usequantum.com.cn',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://praxair.usequantum.com.cn',
    IOS_VERSION: '1.1.8',
    ANDROID_VERSION: '1.1.8',
  },
  // 'bestex-v13-dev': {
  //   API_DOMAIN: 'https://bestex-v13-dev.usequantum.com.cn',
  //   API_DIR: '/facts_backend-2.6/rest',
  //   UPLOAD_URL: 'https://bestex-v13-dev.usequantum.com.cn',
  //   IOS_VERSION: '1.1.8',
  //   ANDROID_VERSION: '1.1.8',
  // },
  // 'bestex-v13-uat': {
  //   API_DOMAIN: 'https://bestex-v13-uat.usequantum.com.cn',
  //   API_DIR: '/facts_backend-2.6/rest',
  //   UPLOAD_URL: 'https://bestex-v13-uat.usequantum.com.cn',
  //   IOS_VERSION: '1.1.8',
  //   ANDROID_VERSION: '1.1.8',
  // }
};
export const APPConfig = FETCH[DEPLOY];
