/*
 * @Author: Xu Xingshan 
 * @Date: 2019-03-20 23:02:18 
 * @Last Modified by: Xu Xingshan
 * @Last Modified time: 2019-06-05 23:05:31
 */
import { Injectable, EventEmitter, Inject } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from '@angular/common';
import { LocalStorageService } from '../service/localstorage.service';
import * as moment from 'moment';

{providedIn: 'root'}

export class AppService {
  public userInfoEvent: EventEmitter<any> = new EventEmitter();
  // public notify: EventEmitter<any> =  new EventEmitter();
  // public geohash: string = '';
  // public shopId: string = '';
  // public searchAddress: any;
  // public choosedAddress: {address: string, index: number} = {address: '', index: 0};
  // public confirmRemark: {remarkText: string, inputText: string} = {remarkText: '', inputText: ''};
  // public orderDetail: any;

  // getTabPagesIndex(pageName) {
  //   const tabPagesIndex: any = {'MsitePage': 0, 'SearchPage': 1, 'OrderPage': 2, 'ProfilePage': 3};
  //   return tabPagesIndex[pageName];
  // }
  constructor(
    public toastController: ToastController,
    @Inject(DOCUMENT) private document: Document,
    public localStorageService:LocalStorageService,
    public translateService:TranslateService) {
  }
  async toastTip(message: string, needsTran) {
    let msg = message;
    if(needsTran){
      msg = this.translateService.instant(message);
    }
  //   msg =  `<div class="left" style="display: flex;">
  //   <span><img src="assets/imgs/aramark/icon_arrow_up.svg" alt=""></span>
  //   <span style="height: 26px;
  //   line-height: 26px;
  //   margin-left: 10px;">${ msg }</span>
  // </div>`;
  // msg =  `<span><img src="assets/imgs/aramark/ico_error_outline.svg" alt=""></span><span>${ msg }</span>`;
    const toast = await this.toastController.create({
        message: msg,
        duration: 3000,
        position: 'bottom',
        showCloseButton:true,
        closeButtonText:this.translateService.instant('close_key'),
        // color: 'warning',
        cssClass: 'tips-class'
      });
    toast.present();
  }

  async toastTipSuccess(message: string, needsTran) {
    let msg = message;
    if(needsTran){
      msg = this.translateService.instant(message);
    }
    const toast = await this.toastController.create({
        message: msg,
        duration: 3000,
        position: 'bottom',
        showCloseButton:true,
        closeButtonText:this.translateService.instant('close_key'),
        // color: 'success',
        cssClass: 'tips-class-success'
      });
    toast.present();
  }

  setGlobalFontSize(fontSizePX) {
    console.log("fontSizePX");
    console.log(fontSizePX);
    this.document.documentElement.style.fontSize = fontSizePX;
  }

  formatDate(str,only){
    only = true;
    if(!str){
      return "";
    }
    if(only){
      return moment(str).format("YYYY-MM-DD");
    }else{
      var lang = this.localStorageService.getStore("APP_LANG");
      moment.locale(lang); 
      if(moment(str).diff(moment())<-1000*24*60*60 ||moment(str).diff(moment())>0){
        return moment(str).format("YYYY-MM-DD")
      }else{
        return moment(str).fromNow();
      }
    }
  }

  formatTime(str, only){
    only = true;
    if(!str){
      return "";
    }
    if(only){
      return moment(str).format("YYYY-MM-DD HH:mm:ss");
    }else{
      var lang = this.localStorageService.getStore("APP_LANG");
      moment.locale(lang); 
      if(moment(str).diff(moment())<-1000*24*60*60 ||moment(str).diff(moment())>0){
        return moment(str).format("YYYY-MM-DD HH:mm:ss")
      }else{
        return moment(str).fromNow();
      }
    }
  }

  convertCodeToTxt(str, txt){
    let desc="";
    try{
      let lang =this.localStorageService.getStore("APP_LANG")
      let txtObj = JSON.parse(txt)||null;
      if(!txtObj[str]||!txt){
        return str;
      }
      switch(lang){
        case "zh_CN":{
          // console.log("zh_CN====");
          // console.log(txtObj[str]);
          // console.log("str===");
  
          // console.log("str===");
  
          desc = txtObj[str].code_desc_zh;
          // console.log(txtObj[str]);
  
          // console.log(desc);
          break;
        }
        case "zh_TW":{
          desc = txtObj[str].code_desc_tr;
          break;
        }
        case "en_US":{
          desc = txtObj[str].code_desc_en;
          break;
        }
      }
      return desc;
    }catch{
      return desc;
    }
  } 

  tranCodeToTxt(str,obj){
    console.log(obj);
    let desc="";
    try{
      let lang =this.localStorageService.getStore("APP_LANG")
      if(!obj){
        return str;
      }
      switch(lang){
        case "zh_CN":{
          // console.log("====txtObj====");
          // console.log(txtObj);
          // console.log("zh_CN====");
          // console.log(txtObj[str]);
          // console.log("str===");
  
          // console.log("str===");
  
          desc = obj.code_desc_zh;
          // console.log(txtObj[str]);
  
          // console.log(desc);
          break;
        }
        case "zh_TW":{
          desc = obj.code_desc_tr;
          break;
        }
        case "en_US":{
          desc = obj.code_desc_en;
          break;
        }
      }
      return desc;
    }catch{
      return desc;
    }
  } 

  quickCodeToArray(str) {
    if (!str) {
      return [];
    }
    try {
      let lang = this.localStorageService.getStore("APP_LANG");
      let data = JSON.parse(this.localStorageService.getStore(str));
      if (!data) {
        return [];
      }
      switch (lang) {
        case "zh_CN":{
          data.forEach((element,index) => {
            element.desc = element.code_desc_zh;
            data[index] = element;
          });
          break;
        }
        case "zh_TW":{
          data.forEach((element,index) => {
            element.desc = element.code_desc_tr;
            data[index] = element;
          });
          break;
        }
        case "en_US":{
          data.forEach((element,index) => {
            element.desc = element.code_desc_en;
            data[index] = element;
          });
          break;
        }
      }
      return data;
    } catch {
      return [];
    }
  }

  conversionCode(code) {
    const lang = JSON.parse(this.localStorageService.getStore('DEVIATION_SEVERITY'));
    const data = lang.filter(res => res.code === code);
    if (data) {
      return data[0].code_desc_zh;
    }
  }

  translateBuilding(code) {
    const lang = JSON.parse(this.localStorageService.getStore('Inspection_Object_Types'));
    if (lang) {
      const data = lang[code];
      if (data.code === code) {
        return data.code_desc_zh;
      }
    }
  }

  log(msg) {
    console.log(msg);
  }

    // cron表达式转成循环条件
    // cron 为空的为单次循环
    // 29 29 16 * * ? * 按天
    // 19 30 16 * * 2,3 * 按周
    // 44 30 16 2,4,21 * ? * 按月
    // 提取特殊字符串长度（scrstr 源字符串 armstr 特殊字符）
    cronChangeDate (str) {
      var toDate={loopType:"",loopValue:"",loopTime:""};
      if (!str) {
        toDate.loopType = '单次'
      } else {
        var result = str.split(' ').join('')
        var nArr = str.split(' ')
        var countData = this.getPlaceholderCount(result)
        if (!countData.count1) { // 没有'?'则是按周循环
          toDate.loopType = '每周'
          var keys = nArr[5]
          var en2cnMap = {
            1: '周天',
            2: '周一',
            3: '周二',
            4: '周三',
            5: '周四',
            6: '周五',
            7: '周六'
          }
          var cnKeys = keys.split(',').map(function (key, idx) {
            return en2cnMap[key];
          })
          toDate.loopValue = cnKeys.join(',')
        } else if (countData.count1 + countData.count2 === 3) {
          toDate.loopType = '每月'
          var mot = []
          var mkeys = nArr[3].split(',')
          for (var i = 0; i < mkeys.length; i++) {
            let mo = mkeys[i] + '日'
            mot.push(mo)
          }
          toDate.loopValue = mot.join(',')
        } else {
          toDate.loopType = '每天'
        }
        toDate.loopTime = nArr[2] + ':' + nArr[1] + ':' + nArr[0]
      }
    return toDate
      // console.log(toDate)  {loopType: "月循环", loopValue: "2号,4号,21号", loopTime: "16:30:44"}
    }
      // 统计字符串中包含某个字符的个数
    getPlaceholderCount (strSource) {
        var count1 = 0 // ?的个数
        var count2 = 0 // *的个数
        strSource.replace(/\*|\?/g, function (m, i) {
          if (m === '?') {
            count1++
          } else if (m === '*') {
            count2++
          }
        });
        var obj={count1:0,count2:0};
        obj.count1 = count1
        obj.count2 = count2
        return obj //返回一个对象，根据需要得到想要的值
    }
}
