import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController, NavParams, NavController } from '@ionic/angular';

@Component({
	selector: 'no-source-img',
	templateUrl: './no-source-img.html',
	styleUrls: ['./no-source-img.scss'],
})
export class NoSourceImgPage implements OnInit {
	isShow = false;
	@Input() titleContent: any;
	isH5 = JSON.parse(this.localStorageService.getStore('isH5'));

	constructor(
		public dataService: DataService,
		public appService: AppService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public navController: NavController,
		public modalController: ModalController,
		public localStorageService: LocalStorageService
		) {
		this.activatedRoute.params.subscribe(param => {

		});
	}



	ngOnInit() {
	
	}

}
