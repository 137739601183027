import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../../../../service';
import { Router } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi

@Component({
	selector: 'form-select-modal',
	templateUrl: './form-select-modal.component.html',
	styleUrls: ['./form-select-modal.component.scss'],
})
export class FormSelectModalComponent implements OnInit {
  queryCriterias = [];
  selectedForm;
  optionList = [];
  searchMessage;
  modalTitle;
  source;
  peopleInOrgId;
  excludeMedical: boolean = false;
  isLoading = false;

	constructor(
		public dataService: DataService,
		public appService: AppService,
		public router: Router,
		public navCtrl: NavController,
		public navParams: NavParams,
		public modalController: ModalController,
    public localStorageService: LocalStorageService
  ) {
		let params = this.navParams.data;
    this.modalTitle = params.modalTitle;
    this.excludeMedical = params.excludeMedical;
    this.selectedForm = params.selectedForm;
    this.getForm();
	}

	ngOnInit() {
		// 设置标题
		document.getElementsByTagName('title').item(0).innerText = '选择' + this.modalTitle;
	}
  
  getForm() {
    this.isLoading = true;
    let forms$;
    if (this.excludeMedical) {
      forms$ = this.dataService.queryForm(this.searchMessage, true);
    } else {
      forms$ = this.dataService.queryForm(this.searchMessage);
    }
    forms$.subscribe(res => {
      if (res && res['STATUS'] === 0) {
        this.isLoading = false;
        this.optionList = res['DATA'];
      }
    })
  }

  markSelected(p) {
    if (this.selectedForm === p) {
      this.selectedForm = null;
    } else {
      this.selectedForm = p;
    }
  }

  isSelected(p) {
    if (this.selectedForm && p.id === this.selectedForm.id) {
      return true;
    }
    return false;
  }

	cancel() {
		this.modalController.dismiss();
	}

	confirm() {
		this.modalController.dismiss({ form: this.selectedForm });
	}
}
