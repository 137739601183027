import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../../../../service';
import { Router } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController, PickerController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi

import { PickerOptions } from "@ionic/core";

import { OrgSelectModalComponent } from '../org-select-modal/org-select-modal.component';
import { PersonSelectModalComponent } from '../person-select-modal/person-select-modal.component';
import { FormSelectModalComponent } from '../form-select-modal/form-select-modal.component';
import { MultiSelectModalComponent } from '../../multi-select-modal/multi-select-modal.component';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { TranslateService } from '@ngx-translate/core';


@Component({
	selector: 'total-filter-modal',
	templateUrl: './total-filter-modal.component.html',
	styleUrls: ['./total-filter-modal.component.scss'],
})
export class TotalFilterModalComponent implements OnInit {
  queryCriterias = [];
  query;
  queryKey = {
    bl: 'businessLine',
    region: 'org_operation',
    area: 'org_area',
    program: 'org_program',
  }

	// 默认筛选条件
  defaultBL = null;
  defaultDivision = null;
  defaultRegion = null;
  defaultProject = null;
  startTime = null;
  endTime = null;
  defaultCheckTable = null;
  courseType = null;
  maintanenceDept = null;
  needFormSelection;
  courseTypeList = [];
  courseTypeListTrans = [];
  maintenanceDeptList = [];
  maintenanceDeptListTrans = [];
  currentDateString = new Date().toISOString();

  // 选表的名字
  inspectionForm;
  // 选表要传递出的数据,第一个是type，第二个是id
  selectCheckTable = [];
  // 选表的数据源
  formPickerDate = [];
  queryCompany;
  isAction;
  queryForm;
  isGov;
  serviceTypeList = [
    { code: '_SYS_WH_15_1', name: '环境服务' },
    { code: '_SYS_WH_15_2', name: '运送服务' },
    { code: '_SYS_WH_15_3', name: '设施服务' },
    { code: '_SYS_WH_15_4', name: '安保服务' },
  ];

  taskStatusList = [
    // { code: '_SYS_PL1_8', name: '草稿' },
    { code: '_SYS_PL1_E', name: '整改' },
    { code: '_SYS_PL1_7', name: '结案' },
  ];

  inspectionTaskStatusList = [
    { code: '_SYS_PL1_8', name: '草稿' },
    { code: '_SYS_PL1_1', name: '进行' },
    { code: '_SYS_PL1_E', name: '整改' },
    { code: '_SYS_PL1_7', name: '结案' },
    { code: '_SYS_PL1_3', name: '撤销' },
  ];

  inspectionMethodList = [
    { code: '_SYS_BS_9', name: '计划检查' },
    { code: '_SYS_BS_10', name: '立即检查' },
  ];
  actionStatusList = [
    { code: '_SYS_PL1_1', name: '进行中' },
    { code: '_SYS_PL1_5', name: '验证中' },
    { code: '_SYS_PL1_7', name: '结案' },
  ];
  isMediaList = [
    { code: '_SYS_AM_1', name: '是' },
    { code: '_SYS_AM_2', name: '否' },
  ];
  noOrg;
  isHidden;
  isGovAction;

	constructor(
		public dataService: DataService,
		public appService: AppService,
		public router: Router,
		public navCtrl: NavController,
		public navParams: NavParams,
		public modalController: ModalController,
    public localStorageService: LocalStorageService,
    public pickerController: PickerController,
    private translateService: TranslateService,
  ) {
		let params = this.navParams.data;
    this.query = params.query;
    this.isAction = params.isAction;
    this.isHidden = params.isHidden;
    this.isGov = params.isGov;
    this.noOrg = params.noOrg;
    this.isGovAction = params.isGovAction;
	}

	ngOnInit() {
		// 设置标题
		document.getElementsByTagName('title').item(0).innerText = '筛选条件';
	}

	// 重置条件
	resetSearchFilters() {
    Object.keys(this.query).forEach((item, index) => {
      this.query[item] = null;
    });
	}

	cancel() {
		this.modalController.dismiss();
	}

	confirm() {
		this.modalController.dismiss({ query: this.query });
  }
  
  async selectOrg(org, type, selectedOrg) {
    // if(!org && (type !== "bl" && type !== 'program')){
    //   this.appService.toastTip('必须先选择上一级组织架构', true);
    //   return;
    // };
    const modal = await this.modalController.create({
			component: OrgSelectModalComponent,
			showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        org: org,
        type: type,
        selectedOrg: selectedOrg,
      }
		});
		modal.onDidDismiss().then(res => {
      document.getElementsByTagName('title').item(0).innerText = '筛选条件';
      if (res.data) {
        this.query[this.queryKey[type]] = res.data.org;
        // // 返回了数据，代表点击了确认键，进行数据处理
        // // 如果选择了业务线，则清除大区、区域、项目的数据
        // if (type == "bl") {
        //   this.query['org_operation'] = null;
        //   this.query['org_area'] = null;
        //   this.query['org_program'] = null;
        // }
        // // 如果选择了大区，则清除区域、项目的数据
        // if (type == "region") {
        //   this.query['org_area'] = null;
        //   this.query['org_program'] = null;
        // }
        // // 如果选择了区域，则清除项目的数据
        // if (type == "area") {
        //   this.query['org_program'] = null;
        // }
      }
		});
		return await modal.present();
  }

  orgProcess(org) {
    return '[' + org.code + '] ' + org.full_name;
  }

  // 更新开始时间格式
	changeStartTimeFormat(event) {
		let selectedDate = event.detail.value;
		if (selectedDate) {
      if (this.endTime && new Date(selectedDate).getTime() - new Date(this.endTime).getTime() > 0) {
        this.appService.toastTip('开始时间不能大于结束时间', true);
      } else {
        this.startTime = new Date(selectedDate).toISOString();
      }
		}
	}
  // 更新结束时间格式
	changeEndTimeFormat(event) {
		let selectedDate = event.detail.value;
		if (selectedDate) {
      if (this.startTime && new Date(selectedDate).getTime() - new Date(this.startTime).getTime() < 0) {
        this.appService.toastTip('结束时间不能小于开始时间', true);
      } else {
        this.endTime = new Date(selectedDate).toISOString();
      }
		}
  }

  // 检查方式
  async selectInspectionMethod() {
		let i = 0;
		if (this.query.inspectionMethod) {
			this.inspectionMethodList.forEach((res, index) =>{
				if (res.code === this.query.inspectionMethod) {
					i = index;
					return;
				}
			})
		}
		let options: PickerOptions = {
			buttons: [
        {
          text: '取消',
          role: 'cancel'
        },
        {
          text: '确认',
          handler: (value: any) => {
            this.query.inspectionMethod = value.inspectionMethod.value;
          }
        }
      ],
      columns: [
        {
          name: 'inspectionMethod',
          options: this.getOptionList(this.inspectionMethodList),
          selectedIndex: i
        }
			]
		};
		let picker = await this.pickerController.create(options);
		return picker.present();
  }

  // 检查类型
  async selectInspectionCategory() {
    const modal = await this.modalController.create({
			component: MultiSelectModalComponent,
			showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        code: '_SYS_BL',
        selectedOptions: this.query.inspectionCategory,
        modalTitle: '检查类型',
        inspectionEHS: true
      }
		});
		modal.onDidDismiss().then(res => {
      document.getElementsByTagName('title').item(0).innerText = '筛选条件';
      if (res.data) {
        this.query.inspectionCategory = res.data.selectedOptions;
      }
		});
		return await modal.present();
  }

  getOptionList(list) {
    let options = [];
		list.forEach(item => {
			options.push({text: item.name, value: item.code});
		});
		return options;
  }
  
  getServiceTypeOptionList() {
		let options = [];
		this.serviceTypeList.forEach(item => {
			options.push({text: item.name, value: item.code});
		});
		return options;
  }

  async selectTaskStatus() {
    if (this.isHidden) {
      const modal = await this.modalController.create({
        component: MultiSelectModalComponent,
        showBackdrop: true,
        backdropDismiss: true,
        componentProps: {
          code: '_SYS_BE',
          selectedOptions: this.query.status,
          modalTitle: '状态',
          issueStatus: true,
        }
      });
      modal.onDidDismiss().then(res => {
        document.getElementsByTagName('title').item(0).innerText = '筛选条件';
        if (res.data) {
          this.query.status = res.data.selectedOptions;
        }
      });
      return await modal.present();
    } else {
      if (this.isAction) {
        this.inspectionTaskStatusList = this.actionStatusList;
      } else if (this.isGov) {
        this.inspectionTaskStatusList = this.taskStatusList;
      }
      let i = 0;
      if (this.query.status) {
        this.inspectionTaskStatusList.forEach((res, index) =>{
          if (res.code === this.query.status) {
            i = index;
            return;
          }
        })
      }
      let options: PickerOptions = {
        buttons: [
          {
            text: '取消',
            role: 'cancel'
          },
          {
            text: '确认',
            handler: (value: any) => {
              this.query.status = value.taskStatus.value;
            }
          }
        ],
        columns: [
          {
            name: 'taskStatus',
            options: this.getOptionList(this.inspectionTaskStatusList),
            selectedIndex: i
          }
        ]
      };
  
      let picker = await this.pickerController.create(options);
      return picker.present();
    }
  }
  
  employeeProcess(emp) {
    if (emp) {
      return '[' + emp.code + '] ' + emp.full_name;
    }
    return;
  }

  formProcess(form) {
    if (form) {
      return form.formName;
    }
    return;
  }

  async selectInspector(selectedPerson) {
    const modal = await this.modalController.create({
			component: PersonSelectModalComponent,
			showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        selectPerson: selectedPerson,
        modalTitle: '检查人',
        source: 'auto',
      }
		});
		modal.onDidDismiss().then(res => {
      document.getElementsByTagName('title').item(0).innerText = '筛选条件';
      if (res.data) {
        this.query.inspector = res.data.person;
      }
		});
		return await modal.present();
  }

  // 选择表格
  async selectForm(selectedForm) {
    const modal = await this.modalController.create({
			component: FormSelectModalComponent,
			showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        selectedForm: selectedForm,
        modalTitle: '表格',
        excludeMedical: true,
      }
		});
		modal.onDidDismiss().then(res => {
      document.getElementsByTagName('title').item(0).innerText = '筛选条件';
      if (res.data) {
        this.query.formSelector = res.data.form;
      }
		});
		return await modal.present();
  }

  getTranslateCode(str) {
    let trans = '';
    if (str) {
      const codes = str.split(',');
      codes.forEach((code, index) => {
        trans += this.translateService.instant(code);
        if (index !== codes.length - 1) {
          trans += ',';
        }
      });
    }
    return trans;
  }

  // 职能部门带队层级
  async selectIssueClassification() {
    const modal = await this.modalController.create({
      component: MultiSelectModalComponent,
      showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        code: '_SAFETY_HEALTH_INSPECT_5',
        selectedOptions: this.query.department_level,
        modalTitle: '职能部门带队层级',
      }
    });
    modal.onDidDismiss().then(res => {
      document.getElementsByTagName('title').item(0).innerText = '筛选条件';
      if (res.data) {
        this.query.department_level = res.data.selectedOptions;
      }
    });
    return await modal.present();
  }

  // 政府检查部门
  async selectGov() {
    const modal = await this.modalController.create({
      component: MultiSelectModalComponent,
      showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        code: '_SAFETY_HEALTH_INSPECT_6',
        selectedOptions: this.query.government_department,
        modalTitle: '政府检查部门',
      }
    });
    modal.onDidDismiss().then(res => {
      document.getElementsByTagName('title').item(0).innerText = '筛选条件';
      if (res.data) {
        this.query.government_department = res.data.selectedOptions;
      }
    });
    return await modal.present();
  }

  // 媒体介入
  async selectMedia() {
    let i = 0;
    if (this.query.status) {
      this.isMediaList.forEach((res, index) =>{
        if (res.code === this.query.status) {
          i = index;
          return;
        }
      })
    }
    let options: PickerOptions = {
      buttons: [
        {
          text: '取消',
          role: 'cancel'
        },
        {
          text: '确认',
          handler: (value: any) => {
            this.query.is_media_intervention = value.taskStatus.value;
          }
        }
      ],
      columns: [
        {
          name: 'taskStatus',
          options: this.getOptionList(this.isMediaList),
          selectedIndex: i
        }
      ]
    };

    let picker = await this.pickerController.create(options);
    return picker.present();
  }
}
